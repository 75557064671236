import './AppointmentBookingModal.scss';
import styles from './AppointmentBookingModal.module.scss';
import { Modal } from 'antd';
import { impactConfig } from 'config/brand/impactConfig';
import { useGetAccessToken } from 'utils/hooks/token';
import { useGetAttachedClinicianDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';
import classNames from 'classnames';

interface AppointmentBookingModalProps {
  onCancel?: () => void;
  visible: boolean;
}

const AppointmentBookingModal = ({ visible, ...props }: AppointmentBookingModalProps) => {
  const { token } = useGetAccessToken(true);

  const { attachedClinicianDetail } = useGetAttachedClinicianDetails();

  const impactAppointmentUrl = `${impactConfig.newAppointmentURL}?clinicianId=${attachedClinicianDetail?.clinician?._id}&authToken=${token}`;

  return (
    <Modal
      className={classNames(styles.modalContainer, 'impactAppointmentModal')}
      title={'Book appointment'}
      bodyStyle={{ padding: '0' }}
      open={visible}
      {...props}
      footer={null}
    >
      <div className={styles.content}>
        <iframe
          title={'impact appointment'}
          width={'100%'}
          height={'100%'}
          src={impactAppointmentUrl}
          frameBorder={0}
        />
      </div>
    </Modal>
  );
};

export default AppointmentBookingModal;
