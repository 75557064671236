import { useAuth0 } from '@auth0/auth0-react';
import Button from 'components/v2/Button/Button';
import { getHelmPractitionerDetailsPath } from 'helm/utils/Path/getHelmPractitionerDetailsPath';
import { useHelmRoutesGenerator } from 'helm/utils/Path/HelmRoutesGenerator';
import { MouseEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IS_HELM_APP } from 'utils/hooks/AccountInfo/clientDetails';
import { useLogout } from 'utils/hooks/logout';
import styles from './HeaderMenu.module.scss';
import { useGetAttachedClinicianDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';
import ImpactAppointmentModal from 'impact/ImpactAppointmentModal/ImpactAppointmentModal';

interface HeaderMenuProps {
  children: JSX.Element;
  menuList: {
    name: string;
    url: string;
    isExternal: boolean;
    openInNewTab?: boolean;
  }[];
}

const HeaderMenu = ({ children, menuList }: HeaderMenuProps) => {
  const { user } = useAuth0();
  const { customFeatures, attachedClinicianDetail } = useGetAttachedClinicianDetails();
  const { logout } = useLogout();
  const { COUNSELLORS_CALENDAR, PRACTITIONER } = useHelmRoutesGenerator();
  const [showMenu, setShowMenu] = useState(false);

  const navigate = useNavigate();

  const navigateTo = (url: string, isExternal: boolean, openInNewTab?: boolean) => {
    if (isExternal) {
      window.open(url, openInNewTab ? '_blank' : '_self');
    } else {
      navigate(url);
    }
  };

  const stopPropagation = (e: MouseEvent) => {
    e.stopPropagation();
  };

  const helmBookNewAppointmentUrl =
    (IS_HELM_APP &&
      attachedClinicianDetail &&
      attachedClinicianDetail.clinician &&
      getHelmPractitionerDetailsPath({
        clinicianId: attachedClinicianDetail.clinician._id,
        counsellorsCalendarDetailsPath: COUNSELLORS_CALENDAR.DETAILS,
        practitionerDetailsPath: PRACTITIONER.DETAILS
      })) ||
    undefined;

  return (
    <div className={styles.container}>
      <div className={styles.menuTrigger} onClick={() => setShowMenu(true)}>
        {children}
      </div>

      {showMenu && (
        <>
          <div className={styles.overlay} onClick={() => setShowMenu(false)} />
          <div className={styles.menuBox}>
            <div className={styles.greeting} onClick={stopPropagation}>
              Hi {user?.name}
            </div>
            {customFeatures?.includes('impactNortheast') && <ImpactAppointmentModal />}
            {helmBookNewAppointmentUrl && (
              <Button className={styles.bookAppointment} onClick={() => navigateTo(helmBookNewAppointmentUrl, false)}>
                <div className={styles.bookAppointmentBtn}>
                  Book new appointment
                  <div className={`material-icons ${styles.calendarIcon}`}>event</div>
                </div>
              </Button>
            )}
            {menuList.map((item, index) => (
              <div
                key={index}
                className={styles.menuItem}
                onClick={() => navigateTo(item.url, item.isExternal, item?.openInNewTab)}
              >
                <div>{item.name}</div>
                <div className={`material-icons ${styles.arrowIcon}`}>arrow_forward</div>
              </div>
            ))}
            <div className={styles.signOut} onClick={() => logout()}>
              Sign Out
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default HeaderMenu;
