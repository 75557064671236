import { clientRecordsInterface } from 'interfaces/Clients/clientsRecord';
import { ClinicianAccountSettings } from 'interfaces/Clinician/clinicianDetails';

export const PAYMENT_TYPE_LABELS = {
  bankTransfer: 'Bank Transfer',
  stripe: 'Online Payment',
  paypal: 'Paypal',
  cash: 'Cash',
  other: 'Other'
};

export interface PaymentMethod {
  _id: string;
  paymentType: 'bankTransfer' | 'stripe' | 'paypal' | 'cash' | 'other';
  bankName?: string;
  accountName?: string;
  accountNumber?: string;
  code?: string;
  instructions?: string;
  email?: string;
  paypalMeLink?: string;
  stripeAccountId?: string;
  stripeConnectedBy?: string;
  stripe?: string;
  stripeFeeMethod?: string;
  stripeStatus?: 'completed' | 'incomplete';
  stripeConnectedBank?: {
    bankName?: string;
    last4?: string;
    routingNumber?: string;
  };
  stripeConnectedAt?: string;
  stripeUpdatedAt?: string;
}

export enum ItemOverview {
  Id = 'id',
  Name = 'name',
  Date = 'date',
  Duration = 'duration',
  Status = 'status',
  Owner = 'owner'
}

export enum ItemColumns {
  Quantity = 'quantity',
  UnitPrice = 'unitPrice',
  TaxRate = 'taxRate',
  Total = 'total'
}

export enum RecipientTypes {
  Client = 'client',
  KeyClientContact = 'keyClientContact',
  Funder = 'funder'
}

interface IRecipient {
  type: RecipientTypes;
  tags?: string[]; // for key client contacts
}

export interface IInvoiceTemplate {
  _id: string;
  accountId: string;
  name: string;
  description?: string;
  shortCode?: string;

  paymentMethods: string[];
  sendTo: IRecipient[];
  recipient: IRecipient;

  invoiceTitle?: string;
  invoiceNotes?: string;
  itemOverviews: ItemOverview[];
  itemColumns: ItemColumns[];
  dueAfter: number; // in days
  invoiceFooter?: string;

  allowDiscount: boolean;
  allowMultipleItems: boolean;

  // required fields
  invoiceId: boolean;
  issueDate: boolean;
  dueDate: boolean;

  practiceLogo: boolean;
  practiceName: boolean;
  practiceAddress: boolean;
  practicePhoneNumber: boolean;
  practiceFax: boolean;
  practiceFormalName: boolean;
  practiceRegistrationNumber: boolean;
  practiceFinanceEmail: boolean;

  recipientAddress: boolean;
  recipientEmail: boolean;
  recipientMobileNumber: boolean;
  recipientReference: boolean;
  recipientRegistrationNumber: boolean;

  medicareClaimant: boolean;
  medicareClaim: boolean;
  medicareServiceProvider: boolean;
  medicareReferral: boolean;
  createdAt: Date;
}

export enum InvoiceStatus {
  ClaimComplete = 'claimComplete',
  ClaimRejected = 'claimRejected',
  ClaimSuccess = 'claimSuccess',
  Closed = 'closed',
  ConfirmPaid = 'confirmPaid',
  Draft = 'draft',
  FullyRefunded = 'fullyRefunded',
  Issued = 'issued',
  MarkedPaid = 'markedPaid',
  Overdue = 'overdue',
  PendingClaim = 'pendingClaim',
  RefundFailed = 'refundFailed',
  Voided = 'voided',
  WriteOff = 'writeOff'
}

export interface Invoice {
  _id: string;
  clinician?: {
    avatar: string;
    email: string;
    mobileNumber: string;
    name: string;
  };
  clientRecord: clientRecordsInterface;
  accountSettings?: ClinicianAccountSettings;
  description: string;
  discount?: {
    type?: 'percent' | 'amount';
    value?: number;
  };
  dueDate: string;
  invoiceId: string;
  issueDate: string;
  items: {
    appointmentId?: string;
    overview: string;
    cost: number;
    tax: number;
    total: number;
    tags?: string[];
    appointmentDate: string;
    description?: string;
    quantity?: string;
  }[];
  paymentMethods: PaymentMethod[];
  practice: {
    address: string;
    logo: string;
    mobileNumber: string;
    name: string;
    fax?: string;
    financeEmail?: string;
    formalName?: string;
    registrationNumber?: string;
  };
  taxRate: number;
  status: InvoiceStatus;
  group?: {
    _id: string;
    name: string;
  };
  medicare?: {
    claimant?: {
      name?: string;
      dateOfBirth?: string;
      medicareNumber?: string;
      irn?: string;
      expiryDate?: string;
      dva?: string;
      phoneNumber?: string;
      address?: string;
    };
    referral?: {
      name?: string;
      date?: string;
      providerNumber?: string;
    };
    serviceProvider?: {
      name?: string;
      providerNumber?: string;
    };
    claim?: {
      dateOfService?: string;
      mbsCode?: string;
    };
  };
  template?: IInvoiceTemplate;
  title?: string;
  recipients?: {
    address?: string;
    email?: string;
    mobileNumber?: string;
    name?: string;
    reference?: string;
    registrationNumber?: string;
  }[];
  invoiceAmount: number;
  footer?: string;
  amountChanged?: boolean;
}
