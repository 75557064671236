import { SpecialistTheme } from 'helm/interfaces/Filter/searchFilter';
import moment from 'moment';
import { isDevelopmentENV } from 'utils/featureToggle/DevelopmentToggle';
import { toCapitalize } from 'utils/generateCamelCase';
import { object, string } from 'yup';
import { config } from 'config/config';

// handle both local and country code
export const getAuMobile = (v: string) => {
  if (config.countryCode === 'au' && v && !isDevelopmentENV()) {
    const formatted = v.startsWith('0') ? `61${v.slice(1)}` : v;
    return `+${formatted}`;
  }
  return `+${v}`;
};

export const initialCustomer = {
  firstName: '',
  lastName: '',
  email: '',
  mobileNumber: '',
  dateOfBirth: '',
  password: '',
  theme: '',
  voucherCode: ['', '', '', '', '', ''] as [string, string, string, string, string, string]
};

export const specialistList = Object.values(SpecialistTheme).map((obj) => ({
  value: obj,
  label:
    obj === SpecialistTheme.Emotions
      ? 'Handling Emotions'
      : obj === SpecialistTheme.All
      ? 'Other difficulties in life'
      : obj === SpecialistTheme.Relationship
      ? 'Relationship Concerns'
      : toCapitalize(obj)
}));

export const p2cThemeOptions = [
  { label: 'Drugs', value: SpecialistTheme.Drugs },
  { label: 'Alcohol', value: SpecialistTheme.Alcohol },
  { label: 'Gambling', value: SpecialistTheme.Gambling }
];

export const passwordValidationSchema = (pwScore: number) =>
  object().shape({
    password: string()
      .required('Please set your password')
      .matches(/.*[!@#$%^&*-].*/, 'Password must meet requirements')
      .matches(/.*[A-Z].*/, 'Password must meet requirements')
      .matches(/.*[a-z].*/, 'Password must meet requirements')
      .matches(/.*[\d].*/, 'Password must meet requirements')
      .test('Password score', 'Week password!', () => pwScore === 4)
      .nullable()
  });

const VALID_VOUCHER_CODES = isDevelopmentENV() ? ['686868'] : ['P2C001'];

export const isValidVoucherCode = (voucherCode: string) => VALID_VOUCHER_CODES.includes(voucherCode);

export const validationSchema = object().shape({
  firstName: string().required('Please enter your first name'),
  lastName: string().required('Please enter your last name'),
  email: string().required('Please enter your email').email('Email must be in valid example@gmail.com format.'),
  theme: string().required('Please select the main focus area for you'),
  dateOfBirth: string()
    .required('Please choose your date of birth')
    .test('Age check', "Be sure that you're over the age of 18", (dob) =>
      moment(dob, 'YYYY-MM-DD').add(18, 'years').isBefore(moment())
    ),
  mobileNumber: string().required('Please enter your mobile number')
});

export const generateP2CGamblingTag = (hasVoucherCode: boolean, voucherCode: string) =>
  hasVoucherCode
    ? isValidVoucherCode(voucherCode)
      ? 'P2C Gambling - Crown Funded'
      : 'P2C Gambling - Crown Referred'
    : 'P2C Gambling - Direct';
