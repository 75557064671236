import { useState } from 'react';
import styles from './ImpactAppointmentModal.module.scss';
import AppointmentBookingModal from './components/AppointmentBookingModal/AppointmentBookingModal';
import Button from '../../components/v2/Button/Button';

const ImpactAppointmentModal = () => {
  const [visibleAppointmentBox, setVisibleAppointmentBox] = useState(false);

  return (
    <div className={styles.container}>
      <Button className={styles.bookAppointment} onClick={() => setVisibleAppointmentBox(!visibleAppointmentBox)}>
        <div className={styles.bookAppointmentBtn}>
          Book new appointment
          <div className={`material-icons ${styles.calendarIcon}`}>event</div>
        </div>
      </Button>
      <AppointmentBookingModal
        visible={visibleAppointmentBox}
        onCancel={() => {
          setVisibleAppointmentBox(false);
        }}
      />
    </div>
  );
};

export default ImpactAppointmentModal;
