import { useMemo } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { Option, Section, SubQuestion } from 'interfaces/Assessment/OnboardingAssessment';

import SectionComponent from './components/Section/Section';
import SubmitButton from '../SubmitButton/SubmitButton';

import { PatientQuestionFormHeaderProps } from '../../../../pages/OnboardingAssessment/components/OnboardingQuestionForm/OnboardingQuestionForm';
import {
  IS_CAW_APP,
  IS_CORDS_APP,
  IS_EASE_APP,
  IS_PORTOBELLO_APP,
  IS_RECHARGE_APP,
  IS_SELECT_APP,
  IS_SOMEONE_HEALTH_APP
} from 'utils/hooks/AccountInfo/clientDetails';
import { getScaleQuestionInitialValue, getScaleQuestionValidation } from '../IndividualQuestions/IndividualQuestions';
import { validatePhoneNumber } from 'pages/SignUp/SignUpForm/components/BasicDetails/validation/BasicDetailsValidation';
import ButtonSH from '../../../../SomeoneHealth/components/ButtonSH/ButtonSH';
import styles from '../IndividualQuestions/IndividualQuestions.module.scss';
import ButtonCaW from '../../../../CaW/components/ButtonCaW/ButtonCaW';
import ButtonEase from 'Ease/components/ButtonEase/ButtonEase';
import ButtonRecharge from 'Recharge/components/ButtonRecharge/ButtonRecharge';
import ButtonSelect from 'Select/components/ButtonSelect/ButtonSelect';
import ButtonPortobello from 'Portobello/components/ButtonPortobello/ButtonPortobello';

interface QuestionSetProps {
  questionId: string;
  sections: Section[];
  completedQuestions: number;
  totalQuestions?: number;
  defaultPatientDetails?: { name: string; picture: string };
  header: (props: PatientQuestionFormHeaderProps) => JSX.Element;
  isSkippable: boolean;
  isFirstQuestionSet: boolean;
  onBack: () => void;
  onSkip: () => void;
  onSubmit: (values: any, setStageToNextQuestion: boolean) => Promise<unknown>;
  setIsSubmitting: (isSubmitting: boolean) => void;
  onSaveAndExit: () => void;
}

const QuestionSet = ({
  questionId,
  sections,
  completedQuestions,
  totalQuestions,
  defaultPatientDetails,
  header: Header,
  isSkippable,
  isFirstQuestionSet,
  onBack,
  onSkip,
  onSubmit,
  setIsSubmitting,
  onSaveAndExit
}: QuestionSetProps) => {
  const { initialValues, validationSchema } = useMemo(() => {
    const { initialValues, validationSchema } = sections.reduce(
      // eslint-disable-next-line complexity
      (finalObject, currentSection) => {
        for (let i = 0; i < currentSection.questions.length; i++) {
          const { id, questionType, maxSelection, options, response, scaleType } = currentSection.questions[i];

          switch (questionType) {
            case 'scale':
              finalObject.initialValues[id] = response || {
                value: getScaleQuestionInitialValue(options as Option[], scaleType)
              };
              finalObject.validationSchema[id] = Yup.object().shape({
                value: !isSkippable
                  ? getScaleQuestionValidation(scaleType).required('Please answer the question')
                  : getScaleQuestionValidation(scaleType).typeError('Please select an option')
              });
              break;
            case 'barSlider':
              finalObject.initialValues[id] = response || { value: 20 };
              finalObject.validationSchema[id] = Yup.object().shape({
                value: !isSkippable
                  ? Yup.number().typeError('Please select an option').required('Please answer the question')
                  : Yup.number().typeError('Please select an option')
              });
              break;
            case 'freeText':
            case 'longText':
              finalObject.initialValues[id] = response || { value: '' };
              finalObject.validationSchema[id] = Yup.object().shape({
                value: !isSkippable ? Yup.string().required('Please answer the question') : Yup.string()
              });
              break;
            case 'hexSlider':
              finalObject.initialValues[id] = response || { value: 6 };
              finalObject.validationSchema[id] = Yup.object().shape({
                value: !isSkippable
                  ? Yup.number().typeError('Please select an option').required('Please answer the question')
                  : Yup.number().typeError('Please select an option')
              });
              break;
            case 'simpleSlider':
              finalObject.initialValues[id] = response || { value: undefined };
              finalObject.validationSchema[id] = Yup.object().shape({
                value: !isSkippable
                  ? Yup.number().typeError('Please select an option').required('Please answer the question')
                  : Yup.number().typeError('Please select an option')
              });
              break;
            case 'multipleAnswers':
              finalObject.initialValues[id] = response || {
                value: (options as SubQuestion[]).reduce((initialValues, { id }) => {
                  initialValues[id] = '';

                  return initialValues;
                }, {} as Record<string, any>)
              };
              finalObject.validationSchema[id] = Yup.object().shape({
                value: Yup.object().shape(
                  (options as SubQuestion[]).reduce((validationSchema, { id }) => {
                    validationSchema[id] = !isSkippable
                      ? Yup.string().required('Please select an option')
                      : Yup.string();

                    return validationSchema;
                  }, {} as Record<string, any>)
                )
              });
              break;
            case 'multipleChoice':
              finalObject.initialValues[id] = response || { value: maxSelection === 0 ? [] : '' };
              finalObject.validationSchema[id] = Yup.object().shape({
                value:
                  maxSelection === 0
                    ? !isSkippable
                      ? Yup.array().min(1, 'Please select an option').of(Yup.string())
                      : Yup.array().of(Yup.string())
                    : !isSkippable
                    ? Yup.string().required('Please select an option')
                    : Yup.string()
              });
              break;
            case 'multipleChoiceFreeText':
              finalObject.initialValues[id] = response || { value: maxSelection === 0 ? [] : '', otherValue: '' };
              finalObject.validationSchema[id] = Yup.object().shape({
                value:
                  maxSelection === 0
                    ? !isSkippable
                      ? Yup.array().min(1, 'Please select an option').of(Yup.string())
                      : Yup.array().of(Yup.string())
                    : !isSkippable
                    ? Yup.string().required('Please select an option')
                    : Yup.string(),
                otherValue: Yup.string()
              });
              break;
            case 'numeric':
              finalObject.initialValues[id] = response || { value: '' };
              finalObject.validationSchema[id] = Yup.object().shape({
                value: !isSkippable
                  ? Yup.string()
                      .test({
                        name: 'numeric',
                        message: 'Please enter a valid number',
                        test: (value: string | unknown) => !isNaN(Number(value))
                      })
                      .required('Please answer the question')
                  : Yup.string().test({
                      name: 'numeric',
                      message: 'Please enter a valid number',
                      test: (value: string | unknown) => !isNaN(Number(value))
                    })
              });
              break;
            case 'selectOther':
              finalObject.initialValues[id] = response || { value: maxSelection === 0 ? [] : '', otherValue: '' };
              finalObject.validationSchema[id] = Yup.object().shape({
                value:
                  maxSelection === 0
                    ? !isSkippable
                      ? Yup.array().min(1, 'Please select an option').of(Yup.string())
                      : Yup.array().of(Yup.string())
                    : !isSkippable
                    ? Yup.string().required('Please select an option')
                    : Yup.string(),
                otherValue: Yup.string().when('value', {
                  is: 'other',
                  then: !isSkippable ? Yup.string().required('Please elaborate') : Yup.string(),
                  otherwise: Yup.string()
                })
              });
              break;
            case 'vote':
              finalObject.initialValues[id] = response || {
                value: (options as Option[]).reduce((initialValues, { key }) => {
                  initialValues[key] = 0;

                  return initialValues;
                }, {} as Record<string, any>)
              };
              finalObject.validationSchema[id] = Yup.object().shape({
                value: Yup.object().shape(
                  (options as Option[]).reduce((validationSchema, { key }) => {
                    validationSchema[key] = !isSkippable
                      ? Yup.number().required('Please vote on this question')
                      : Yup.number();

                    return validationSchema;
                  }, {} as Record<string, any>)
                )
              });
              break;
            case 'phone':
              finalObject.initialValues[id] = response || { value: '' };
              finalObject.validationSchema[id] = Yup.object().shape({
                value: !isSkippable
                  ? Yup.string()
                      .required('Please answer the question')
                      .test('validatePhoneNumber', 'Invalid Phone Number', async (value) => {
                        const phoneValidate = await validatePhoneNumber(value || '', true);
                        return phoneValidate.valid;
                      })
                  : Yup.string()
                      .test('validatePhoneNumber', 'Invalid Phone Number', async (value) => {
                        if (!value) {
                          return true;
                        }

                        const phoneValidate = await validatePhoneNumber(value || '', true);
                        return phoneValidate.valid;
                      })
                      .nullable()
              });
              break;
            case 'email':
              finalObject.initialValues[id] = response || { value: '' };
              finalObject.validationSchema[id] = Yup.object().shape({
                value: !isSkippable
                  ? Yup.string().required('Please answer the question').email('Please enter a valid email address')
                  : Yup.string().email('Please enter a valid email address').nullable()
              });
              break;
            case 'date':
              finalObject.initialValues[id] = response || { value: '' };
              finalObject.validationSchema[id] = response || { value: '' };
              validationSchema[id] = Yup.object().shape({
                value: !isSkippable ? Yup.string().required('Please answer the question') : Yup.string()
              });
              break;

            default:
              break;
          }
        }

        return finalObject;
      },
      { initialValues: {} as Record<string, any>, validationSchema: {} as Record<string, any> }
    );

    return { initialValues, validationSchema: Yup.object().shape(validationSchema) };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sections]);

  const handleSkip = async (values: any) => {
    await handleSubmit(values);

    onSkip();
  };

  const handleSubmit = async (values: any) => {
    setIsSubmitting(true);

    await onSubmit(values, true);

    setIsSubmitting(false);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={false}
        onSubmit={(values) => handleSubmit(values)}
        enableReinitialize
      >
        <Form className="question-form-container" noValidate>
          <Header
            completedQuestions={completedQuestions}
            totalQuestions={totalQuestions}
            patientDetails={defaultPatientDetails}
            isFirstQuestionSet={isFirstQuestionSet}
            isSkippable={isSkippable}
            onBack={onBack}
            onSkip={handleSkip}
            onSaveAndExit={onSaveAndExit}
          />
          {sections.map((section, index) => (
            <SectionComponent
              questionId={questionId}
              key={index}
              defaultPatientDetails={defaultPatientDetails}
              section={section}
            />
          ))}
          {IS_SOMEONE_HEALTH_APP ? (
            <ButtonSH type="submit" className={styles.floatingBtn}>
              Next
            </ButtonSH>
          ) : IS_CAW_APP ? (
            <ButtonCaW type="submit" className={styles.floatingBtn}>
              Next
            </ButtonCaW>
          ) : IS_EASE_APP ? (
            <ButtonEase type="submit" className={styles.floatingBtn}>
              Next
            </ButtonEase>
          ) : IS_RECHARGE_APP ? (
            <ButtonRecharge type="submit" className={styles.floatingBtn}>
              Next
            </ButtonRecharge>
          ) : IS_SELECT_APP ? (
            <ButtonSelect type="submit" className={styles.floatingBtn}>
              Next
            </ButtonSelect>
          ) : IS_PORTOBELLO_APP ? (
            <ButtonPortobello type="submit" className={styles.floatingBtn}>
              Next
            </ButtonPortobello>
          ) : (
            <SubmitButton type="submit">
              {IS_CORDS_APP ? (
                <>
                  {completedQuestions === (totalQuestions ?? 1) - 1 ? 'Confirm and submit assessment' : 'Next'}
                  <i className="material-icons-outlined">arrow_circle_right</i>
                </>
              ) : (
                'Next'
              )}
            </SubmitButton>
          )}
        </Form>
      </Formik>
    </>
  );
};

export default QuestionSet;
